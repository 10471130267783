// Core

body {

  .app-sidebar-wrapper-lg {
    min-height: 100%;
    overflowY: auto;
    display: flex;
    flex-direction: column;
    paddingTop: 0;
    width: $sidebar-width;
  }

  .app-sidebar-menu {
    height: calc(100vh - #{$header-height});
  }

  .app-sidebar-wrapper {
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 60;
    position: static;
    width: $sidebar-width;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-sidebar-wrapper-fixed {
    position: fixed;
  }


  .app-sidebar-logo {
    width: 100%;
    height: 100%;
   // width: 160px;
   // height: 32px;
  }
}
