.filterRow {
    display: grid;
    grid-template-columns: 4fr 5fr 5fr 3fr 3fr 1fr;
    background-color: rgba(245, 147, 0, 0.1);
    border-radius: 15px;
    align-items: center;
    grid-column-gap: 10px;
    padding: 15px 10px;
    width: 99%;
    margin: auto;
}

.filterAutocomplete {

    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        padding: 0px !important;
    }

    .MuiFilledInput-input {
        padding: 5px 0px 5px 0px;
        font-size: 12px;
    }

    .MuiFilledInput-underline:before {
        border: 0;
    }

    .MuiInputLabel-filled {
        transform: translate(12px, 7px) scale(1);
        font-size: 12px;
    }

    .MuiFilledInput-underline:after {
        border: 0;
    }

    .MuiSelect-select:focus {
        background-color: #ffffff;
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px, -12px) scale(0.90) !important;
        color: #F59300;
    }
}


.header2-text-label {
    display: none;
}

#RBS-Scheduler-root .anticon[tabindex] {
    display: none;
}


